
export const themeColor = '#D3D3D3';

export const sessionStorage_KeyName = 'P5_Toolkit_UserName';

//column header names
export const SrNo = 'Sr. No.';
export const Territory = 'Territory';
export const Excise_Lic_Code = 'Excise Lic Code';
export const Outlet_Name = 'Outlet Name';
export const Bira_ID = 'Bira ID';
export const Depot = 'Depot';
export const Outlet_Channel = 'Outlet Channel';
export const SO = 'SO';
export const Sub_Channel = 'Sub Channel';
export const Category = 'Category';
export const Group_Name = 'Group Name';
export const Group_FPR = 'Group FPR';
export const Segment = 'Segment';
export const Premiumness = 'Premiumness';
export const New_Outlet = 'New Outlet';
export const Current_Contract = 'Current Contract';
export const Aim_to_Contract = 'Aim to Contract';
export const L3M_Mass = 'L3M_Mass';
export const L3M_Prem = 'L3M_Prem.';
export const L3M_Keg = 'L3M_Keg';
export const L3M_Prem_MS = 'L3M_Prem_MS';
export const L3M_Prem_Industry = 'L3M_Prem Industry';
export const Prem = 'Prem.';
export const Industry_Draft_Account = 'Industry Draft Account';
export const R_Mass = 'R_Mass';
export const R_White = 'R_White';
export const R_Rise = 'R_Rise';
export const R_Gold = 'R_Gold';
export const R_Blonde = 'R_Blonde';
export const R_Premium = 'R_Premium';
export const R_Innovation = 'R_Innovation';
export const R_KegsPrem = 'R_KegsPrem';
export const R_KegsInnovation = 'R_KegsInnovation';
export const R_KegsTotal = 'R_KegsTotal';
export const P_Mass = 'P_Mass';
export const P_White = 'P_White';
export const P_Rise = 'P_Rise';
export const P_Gold = 'P_Gold';
export const P_Blonde = 'P_Blonde';
export const P_Premium = 'P_Premium';
export const P_Innovation = 'P_Innovation';
export const Total_vol_planned = 'Total vol. planned';
export const P_KegsPrem = 'P_KegsPrem';
export const P_KegsInnovation = 'P_KegsInnovation';
export const P_KegsTotal = 'P_KegsTotal';
export const Change_Vs_Recommended = 'Change Vs Recommended';
export const Remarks = 'Remarks';
export const Comments = 'Comments (If required)';
export const Rebate_Mass = 'Rebate_Mass';
export const Rebate_Premium = 'Rebate_Premium';
export const Rebate_Innovation = 'Rebate_Innovation';
export const Rebate_KegsPrem = 'Rebate_KegsPrem';
export const Rebate_KegsInnovation = 'Rebate_KegsInnovation';
export const Avg_CPC = 'Avg. CPC';
export const Avg_CPK = 'Avg. CPK';

//column field names
export const field_id = 'id';
export const field_territory = 'territory';
export const field_exciseLICCode = 'exciseLICCode';
export const field_outletName = 'outletName';
export const field_biraId = 'biraId';
export const field_depot = 'depot';
export const field_outletChannel = 'outletChannel';
export const field_salesOfficer = 'salesOfficer';
export const field_subChannel = 'subChannel';
export const field_category = 'category';
export const field_groupName = 'groupName';
export const field_groupFPR = 'groupFPR';
export const field_segment = 'segment';
export const field_premiumness = 'premiumness';
export const field_newOutlet = 'newOutlet';
export const field_currentContract = 'currentContract';
export const field_aimToContract = 'aimToContract';
export const field_l3MonthsMass = 'l3MonthsMass';
export const field_l3MonthsPremium = 'l3MonthsPremium';
export const field_l3Monthskeg = 'l3Monthskeg';
export const field_l3MonthsPremiumMS = 'l3MonthsPremiumMS';
export const field_l3MonthsPremiumIndustry = 'l3MonthsPremiumIndustry';
export const field_premium = 'premium';
export const field_industryDraftAccount = 'industryDraftAccount';
export const field_rMass = 'rMass';
export const field_rWhite = 'rWhite';
export const field_rRise = 'rRise';
export const field_rGold = 'rGold';
export const field_rBlonde = 'rBlonde';
export const field_rPremium = 'rPremium';
export const field_rInnovation = 'rInnovation';
export const field_rKegsPremium = 'rKegsPremium';
export const field_rKegsInnovation = 'rKegsInnovation';
export const field_rKegsTotal = 'rKegsTotal';
export const field_pMass = 'pMass';
export const field_pWhite = 'pWhite';
export const field_pRise = 'pRise';
export const field_pGold = 'pGold';
export const field_pBlonde = 'pBlonde';
export const field_pPremium = 'pPremium';
export const field_pInnovation = 'pInnovation';
export const field_totalVolumePlanned = 'totalVolumePlanned';
export const field_pKegsPremium = 'pKegsPremium';
export const field_pKegsInnovation = 'pKegsInnovation';
export const field_pKegsTotal = 'pKegsTotal';
export const field_changeVsRecommended = 'changeVsRecommended';
export const field_remarks = 'remarks';
export const field_comments = 'comments';
export const field_rebateMass = 'rebateMass';
export const field_rebatePremium = 'rebatePremium';
export const field_rebateInnovation = 'rebateInnovation';
export const field_rebateKegsPremium = 'rebateKegsPremium';
export const field_rebateKegsInnovation = 'rebateKegsInnovation';
export const field_averageCPC = 'averageCPC';
export const field_averageCPK = 'averageCPK';

//Column group header
export const L3M_B9_Sales = 'L3M B9 Sales (Average Monthly)';
export const Industry_Forecasts = 'Industry Forecasts(pm)';
export const Recommended_Volume = 'Recommended volume(In Cases or Kegs)';
export const Planned_Volume = 'Planned volume(In Cases or Kegs)';
export const Rebate = 'Rebate (In Cases or Kegs) as per Individual market slabs/contract slabs';

export const ODD_OPACITY = 0.2;

export const Export_File_Name = "Outlet_Planning_Tool.xlsx";

//Summery view page
export const Role = "Role";
export const Channel = "Channel";
export const Name = "Name";
export const Universe = "Universe";
export const Premium = "Premium";
export const Premkegs = "Premkegs";
export const Innovkegs = "Innovkegs";
export const Mass = "Mass";
export const Innovation="Innovation";
export const White="White";
export const Rise="Rise";
export const Gold="Gold";
export const Blonde="Blonde";
export const Comment_Packaged = "Comment(Packaged)";
export const Comment_Kegs = "Comment_Kegs";

export const field_role = "role";
export const field_channel = "channel";
export const field_name = "name";
export const field_universe = "universe";
export const field_planned_premium = "planned_Premium";
export const field_planneD_PREMKEGS = "planneD_PREMKEGS";
export const field_planneD_INNOVKEGS = "planneD_INNOVKEGS";
export const field_planned_Mass = "planned_Mass";
export const field_planned_Innovation = "planned_Innovation";

export const field_recommended_premium = "recommended_Premium";
export const field_recommendeD_PREMKEGS='recommendeD_PREMKEGS';
export const field_recommendeD_INNOVKEGS='recommendeD_INNOVKEGS';
export const field_recommended_Mass = "recommended_Mass";
export const field_recommended_Innovation = "recommended_Innovation";
export const field_recommended_white = "recommended_white";
export const field_recommended_Rise = "recommended_Rise";
export const field_recommended_Gold = "recommended_Gold";
export const field_recommended_Blonde = "recommended_Blonde";

export const field_comment_Packaged = "comment_Packaged";
export const field_comment_Kegs = "comment_Kegs";

export const recommended_summary_title = 'Recommended (# of Cases/Kegs)';

export const planned_summary_title = 'Planned (# of Cases/Kegs)';

// RECOMMENDED_MASS  dec
// RECOMMENDED_INNOVATION dec
// PLANNED_MASS  int
// PLANNED_INNOVATION  int

export const field_Target_premium = "target_Premium";
export const field_Target_PREMKEGS='target_KegsPremium';
export const field_Target_INNOVKEGS='target_KegsInnovation';
export const field_Target_Mass = "target_Mass";
export const field_Target_Innovation = "target_Innovation";
export const field_Target_white = "target_White";
export const field_Target_Rise = "target_Rise";
export const field_Target_Gold = "target_Gold";
export const field_Target_Blonde = "target_Blonde";