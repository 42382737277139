import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import Athena from '../../Metadata/Athena.png';
import './navBar.css';

const Navbar = ({logOut, userName}: any) => {
  const dispatch = useDispatch();
  return (
    <>
      
      <Box display="flex" justifyContent="space-between" p={6.7}
      sx={{
        backgroundImage: `url(${Athena})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
        }}>
      </Box>

      {/* <div className='parent-div' style={{ backgroundColor: `${themeColor}` }}>
        <img className='logo' src={Bira_Logo} alt='Not found'/>
          
        <label className='title'> Outlet Planning Tool </label>
        <div className='show-hide-button'>
          <label className='userName-text'>{userName}</label>
          <Button onClick={() => {
            logOut();
            sessionStorage.removeItem(sessionStorage_KeyName);
          }} className='logout-button'>Log Out</Button>
        </div>
      </div> */}
    </>
  )
}

export default Navbar;