import { LoginType, MsalAuthProvider } from 'react-aad-msal';
 
// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/44969b72-0735-4ff3-9e2e-3a3e4060133b',
    clientId: '5d9f4088-93be-48c8-bd71-34b237aae343',
    redirectUri: 'https://outletlevelplanningtool.bira91.com' //'https://p5testapp.azurewebsites.net' 
   //  redirectUri: 'https://planningtool5test.azurewebsites.net' //'https://p5testapp.azurewebsites.net' 
    // redirectUri: 'http://localhost:3000' //'https://p5testapp.azurewebsites.net' 
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}
 
// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)