
// export const getGridDataURL = "https://planningtoolp5app.azurewebsites.net/api/GetPlanningDataNew?code=VFm_YpE0kKSEMufVddisI7YkpdtTCeGbAt3h4p5xRvy9AzFuzNFudw==";
// export const getTotalCalculatedDataURL = "https://planningtoolp5app.azurewebsites.net/api/GetSummationData?code=VP31IbVLUJXp8aEGQ-HaYx-nZtJM_AxgpjdHIfTZDJE3AzFujL_ihw==";
// export const updateGridDataURL = 'https://planningtoolp5app.azurewebsites.net/api/UpdatePlanningData?code=ezWeo3on3-9I1dJUqJevKyQg48ctNJxP1UhjNoBGDW3DAzFudxSyoQ==';
// export const exportDataURL = 'https://planningtoolp5app.azurewebsites.net/api/GetDataToExportExcel?code=KodsksJJXZ1b4DtCJdgaxT1J0_LUAmg_MYw7-eHg9rSiAzFuMi_H9A==';
// export const summeryDataURL = 'https://planningtoolp5app.azurewebsites.net/api/GetSummaryOperation?code=2mE6tFOBErSaBAsGdnRhrSjmqkWXUUhppBH60M0nPR_2AzFuX1q7Jg==';
// export const userDetailsDataURL = 'https://planningtoolp5app.azurewebsites.net/api/GetUserDetails?code=qU-_yCtC-EnW7UiaDsR70wov9uwX_J93mSb20lsKSyHGAzFuPVJiQw==';
// export const targetDataURL = 'https://planningtoolp5app.azurewebsites.net/api/GetTargetOperation?code=VFm_YpE0kKSEMufVddisI7YkpdtTCeGbAt3h4p5xRvy9AzFuzNFudw==';






export const getGridDataURL = "https://outletplanningtool.azurewebsites.net/api/GetPlanningDataNew?code=iB5NplhiT5K9yktB0o5P9mPRPt2_5cBrgthppyjRVOqGAzFuPj7n2w==";
export const getTotalCalculatedDataURL = "https://outletplanningtool.azurewebsites.net/api/GetSummationData?code=iB5NplhiT5K9yktB0o5P9mPRPt2_5cBrgthppyjRVOqGAzFuPj7n2w==";
export const updateGridDataURL = 'https://outletplanningtool.azurewebsites.net/api/UpdatePlanningData?code=iB5NplhiT5K9yktB0o5P9mPRPt2_5cBrgthppyjRVOqGAzFuPj7n2w==';
export const exportDataURL = 'https://outletplanningtool.azurewebsites.net/api/GetDataToExportExcel?code=iB5NplhiT5K9yktB0o5P9mPRPt2_5cBrgthppyjRVOqGAzFuPj7n2w==';
export const summeryDataURL = 'https://outletplanningtool.azurewebsites.net/api/GetSummaryOperation?code=iB5NplhiT5K9yktB0o5P9mPRPt2_5cBrgthppyjRVOqGAzFuPj7n2w==';
export const userDetailsDataURL = 'https://outletplanningtool.azurewebsites.net/api/GetUserDetails?code=iB5NplhiT5K9yktB0o5P9mPRPt2_5cBrgthppyjRVOqGAzFuPj7n2w==';
export const targetDataURL = 'https://outletplanningtool.azurewebsites.net/api/GetTargetOperation?code=iB5NplhiT5K9yktB0o5P9mPRPt2_5cBrgthppyjRVOqGAzFuPj7n2w==';
