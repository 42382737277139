import { Box, Container } from '@mui/system';
import { AuthenticationState, AzureAD } from 'react-aad-msal';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { authProvider } from './authProvider';
import Index from './components';
import Login from './components/Login/Login';
import SummaryView from './components/SummaryView/SummaryView';
import { sessionStorage_KeyName } from './components/_data/constants';
import TargetView from './components/TargetView/TargetView';

function App() {
  return (
    <AzureAD provider={authProvider}>
      {
        ({ login, logout, authenticationState, error, accountInfo }: any) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              sessionStorage.setItem(sessionStorage_KeyName, accountInfo.account.userName);
              return (
                <Container fixed >
                  <Box sx={{ height: '100vh' }} >
                    <BrowserRouter>
                      <Routes>
                        <Route path="*" element={
                          <div className='Login_Div'>
                            <h2 className='authenticating-msg'>Page Not Found</h2>
                          </div>
                        } />
                        <Route path="/summary-view" element={<SummaryView />} />
                        <Route path="/target-view" element={<TargetView />} />
                        <Route path="/" element={<Index logOut={logout} userName={accountInfo.account.userName} />} />
                      </Routes>
                    </BrowserRouter>
                  </Box>
                </Container>
              );
            case AuthenticationState.Unauthenticated:
              return (
                <Login login={login} />
              );
            case AuthenticationState.InProgress:
              return (
                <div className='Login_Div'>
                  <p className='authenticating-msg'>Authenticating..</p>
                </div>
              )
          }
        }
      }
    </AzureAD>
  );
}

export default App;

/*<>
                      <div className='Login_Error_Div'>
                        <label className='error-msg'>An error occurred during authentication, please try again!</label>
                        <div style={{width: '100%'}}></div>
                        <Button variant="contained" style={{marginTop: '1%'}} onClick={login}>Login</Button>
                      </div>
                    </>
*/

/*<div className='Login_Div'>
                    <Button variant="contained" onClick={login}>Login</Button>
                  </div>*/