import axios from 'axios';
import { sessionStorage_KeyName } from '../../components/_data/constants';
import { exportDataURL, getGridDataURL, getTotalCalculatedDataURL, summeryDataURL, targetDataURL, updateGridDataURL, userDetailsDataURL } from '../url';

function postData (url: any, data: any): Promise<any>{
    return axios.post(url, data);
}

function getData (url: any): Promise<any>{
    return axios.get(url);
}

function putData (url: any, data: any): Promise<any>{
    return axios.put(url, data);
}

export const getGridData = (data: any) => {
    const formData = {
        ...data,
        userEmail: sessionStorage.getItem(sessionStorage_KeyName)
    }
    return postData(getGridDataURL, formData);
}

export const getTotalCalculationData = (data: any) => {
    const formData = {
        ...data,
        userEmail: sessionStorage.getItem(sessionStorage_KeyName)
    }
    return postData(getTotalCalculatedDataURL, formData);
}

export const updateGridData = (data: any) => {
    return putData(updateGridDataURL, data);
}

export const getExportData = () => {
    const formData = {
        userEmail: sessionStorage.getItem(sessionStorage_KeyName)
    }
    return postData(exportDataURL, formData);
}

export const getSummeryView = () => {
    const formData = {
        userEmail: sessionStorage.getItem(sessionStorage_KeyName)
    }
    return postData(summeryDataURL, formData);
}

export const getUserDetails = () => {
    const formData = {
        userEmail: sessionStorage.getItem(sessionStorage_KeyName)
    }
    return postData(userDetailsDataURL, formData);
}

export const getTargetView = () => {
    const formData = {
        userEmail: sessionStorage.getItem(sessionStorage_KeyName)
    }
    return postData(targetDataURL, formData);
}