import * as allActionTypes from '../actionTypes/girdActionTypes';

const initialState: any = {
    isGridDataLoading: false,
    gridData: [],
    isTotalCalculatedDataIsLoading: false,
    totalCalculatedData: [],
    gridColumnsVisibility: [],
    summeryViewData: [],
    isSummeryDataLoading: false,
    userDetails: [],
    isUserDetailsLoading: false,
    gridUpdatedData:[],
    isTargetGridLoading: false,
    targetList:[]
}

const gridReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case allActionTypes.SHOW_HIDE_GRID_COLUMNS:
            return {
                ...state,
                gridColumnsShowHideState: !state.gridColumnsShowHideState,
            }
        
        case allActionTypes.GET_GRID_DATA_START:
            return {
                ...state,
                isGridDataLoading: true,
                gridData: []
            }
        
        case allActionTypes.GET_GRID_DATA_SUCCESS:
            return {
                ...state,
                isGridDataLoading: false,
                gridData: action.payload.data
            }
        
        case allActionTypes.GET_GRID_DATA_FAILED:
            return {
                ...state,
                isGridDataLoading: false,
                gridData: []
            }
        
        case allActionTypes.GET_TOTAL_CALCULATED_DATA_START:
            return {
                ...state,
                isTotalCalculatedDataIsLoading: true,
                totalCalculatedData: []
            }
        
        case allActionTypes.GET_TOTAL_CALCULATED_DATA_SUCCESS:
            return {
                ...state,
                isTotalCalculatedDataIsLoading: false,
                totalCalculatedData: action.payload.data
            }
        
        case allActionTypes.GET_TOTAL_CALCULATED_DATA_FAILED:
            return {
                ...state,
                isTotalCalculatedDataIsLoading: false,
                totalCalculatedData: []
            }
        
        case allActionTypes.GRID_COLUMNS_VISIBILITY:
            return {
                ...state,
                gridColumnsVisibility: action.payload,
            }

        case allActionTypes.GET_SUMMERY_VIEW_START:
            return {
                ...state,
                isSummeryDataLoading: true,
                summeryViewData: []
            }

        case allActionTypes.GET_SUMMERY_VIEW_SUCCESS:
            return {
                ...state,
                isSummeryDataLoading: false,
                summeryViewData: action.payload.data
            }

        case allActionTypes.GET_SUMMERY_VIEW_FAILED:
            return {
                ...state,
                isSummeryDataLoading: false,
                summeryViewData: []
            }
        
        case allActionTypes.GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                isUserDetailsLoading: false,
                userDetails: action.payload.data
            }

        case allActionTypes.GET_USER_DETAILS_FAILED:
            return {
                ...state,
                isUserDetailsLoading: false,
                userDetails: []
            }

        case allActionTypes.GET_GRID_UPDATED_DATA:
            return {
                ...state,
                gridUpdatedData: action.payload.data || []
            }

            case allActionTypes.GET_TARGET_VIEW_START_SUCCESS:
            return {
                ...state,
                isTargetGridLoading: false,
                targetList: action.payload.data
            }

        case allActionTypes.GET_TARGET_VIEW_START_FAILED:
            return {
                ...state,
                isTargetGridLoading: false,
                targetList: []
            }

        case allActionTypes.GET_TARGET_VIEW_START:
            return {
                ...state,
                isTargetGridLoading: true,
                targetList: action.payload.data || []
            }
        default:
            return { ...state };
    }
};

export default gridReducer;