import * as allActionTypes from '../actionTypes/girdActionTypes';

export const showHideGridColumns = () => ({
  type: allActionTypes.SHOW_HIDE_GRID_COLUMNS
});

export const getGridColumnsStart = () => ({
  type: allActionTypes.GET_GRID_DATA_START,
});

export const getGridColumnsSuccess = (payload: any) => ({
  type: allActionTypes.GET_GRID_DATA_SUCCESS,
  payload
});

export const getGridColumnsFailed = (payload: any) => ({
  type: allActionTypes.GET_GRID_DATA_FAILED,
  payload
});

export const getTotalCalculatedDataStart = () => ({
  type: allActionTypes.GET_TOTAL_CALCULATED_DATA_START,
});

export const getTotalCalculatedDataSuccess = (payload: any) => ({
  type: allActionTypes.GET_TOTAL_CALCULATED_DATA_SUCCESS,
  payload
});

export const getTotalCalculatedDataFailed = (payload: any) => ({
  type: allActionTypes.GET_TOTAL_CALCULATED_DATA_FAILED,
  payload
});

export const updateGridDataSuccess = (payload: any) => ({
  type: allActionTypes.UPDATE_GRID_DATA_SUCCESS,
  payload
});

export const updateGridDataFailed = (payload: any) => ({
  type: allActionTypes.UPDATE_GRID_DATA_FAILED,
  payload
});

export const gridColumnsVisibility = (payload: any) => ({
  type: allActionTypes.GRID_COLUMNS_VISIBILITY,
  payload
});

export const getSummeryViewStart = () => ({
  type: allActionTypes.GET_SUMMERY_VIEW_START,
});

export const getSummeryViewSuccess = (payload: any) => ({
  type: allActionTypes.GET_SUMMERY_VIEW_SUCCESS,
  payload
});

export const getSummeryViewFailed = (payload: any) => ({
  type: allActionTypes.GET_SUMMERY_VIEW_FAILED,
  payload
});

export const getUserDetailsSuccess = (payload: any) => ({
  type: allActionTypes.GET_USER_DETAILS_SUCCESS,
  payload
});

export const getUserDetailsFailed = (payload: any) => ({
  type: allActionTypes.GET_USER_DETAILS_FAILED,
  payload
});

export const getGridUpdatedData = (payload: any) => ({
  type: allActionTypes.GET_GRID_UPDATED_DATA,
  payload
});

export const getTargetView = (payload: any) => ({
  type: allActionTypes.GET_TARGET_VIEW_START,
  payload
});

export const getTargetViewSuccess = (payload: any) => ({
  type: allActionTypes.GET_TARGET_VIEW_START_SUCCESS,
  payload
});

export const getTargetViewFailed = (payload: any) => ({
  type: allActionTypes.GET_TARGET_VIEW_START_FAILED,
  payload
});