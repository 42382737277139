
import { GridColDef } from '@mui/x-data-grid-pro';
import * as Constants from './constants';

export const column: GridColDef[] = [
  {
    field: Constants.field_id,
    headerName: Constants.SrNo,
    headerClassName: "header-color",
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_territory,
    headerName: Constants.Territory,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_exciseLICCode,
    headerName: Constants.Excise_Lic_Code,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_outletName,
    headerName: Constants.Outlet_Name,
    headerClassName: "header-color",
    minWidth: 250,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_biraId,
    headerName: Constants.Bira_ID,
    headerClassName: "header-color",
    minWidth: 120,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_depot,
    headerName: Constants.Depot,
    headerClassName: "header-color",
    minWidth: 220,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_outletChannel,
    headerName: Constants.Outlet_Channel,
    headerClassName: "header-color",
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_salesOfficer,
    headerName: Constants.SO,
    headerClassName: "header-color",
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    sortable: true,
    pinnable: true,
  },
  {
    field: Constants.field_subChannel,
    headerName: Constants.Sub_Channel,
    headerClassName: "header-color",
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_category,
    headerName: Constants.Category,
    headerClassName: "header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_groupName,
    headerName: Constants.Group_Name,
    headerClassName: "header-color",
    minWidth: 220,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_groupFPR,
    headerName: Constants.Group_FPR,
    headerClassName: "header-color",
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_segment,
    headerName: Constants.Segment,
    headerClassName: "header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_premiumness,
    headerName: Constants.Premiumness,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_newOutlet,
    headerName: Constants.New_Outlet,
    headerClassName: "header-color",
    minWidth: 40,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_currentContract,
    headerName: Constants.Current_Contract,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: true,
  },
  {
    field: Constants.field_aimToContract,
    headerName: Constants.Aim_to_Contract,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_l3MonthsMass,
    headerName: Constants.L3M_Mass,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_l3MonthsPremium,
    headerName: Constants.L3M_Prem,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_l3Monthskeg,
    headerName: Constants.L3M_Keg,
    headerClassName: "header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_l3MonthsPremiumMS,
    headerName: Constants.L3M_Prem_MS,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_l3MonthsPremiumIndustry,
    headerName: Constants.L3M_Prem_Industry,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_premium,
    headerName: Constants.Prem,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_industryDraftAccount,
    headerName: Constants.Industry_Draft_Account,
    headerClassName: "header-color",
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rMass,
    headerName: Constants.R_Mass,
    headerClassName: "header-color",
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rWhite,
    headerName: Constants.R_White,
    headerClassName: "header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rRise,
    headerName: Constants.R_Rise,
    headerClassName: "header-color",
    minWidth: 60,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rGold,
    headerName: Constants.R_Gold,
    headerClassName: "header-color",
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rBlonde,
    headerName: Constants.R_Blonde,
    headerClassName: "header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rPremium,
    headerName: Constants.R_Premium,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rInnovation,
    headerName: Constants.R_Innovation,
    headerClassName: "header-color",
    minWidth: 120,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rKegsPremium,
    headerName: Constants.R_KegsPrem,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rKegsInnovation,
    headerName: Constants.R_KegsInnovation,
    headerClassName: "header-color",
    minWidth: 120,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rKegsTotal,
    headerName: Constants.R_KegsTotal,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_pMass,
    headerName: Constants.P_Mass,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_pWhite,
    headerName: Constants.P_White,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_pRise,
    headerName: Constants.P_Rise,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_pGold,
    headerName: Constants.P_Gold,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_pBlonde,
    headerName: Constants.P_Blonde,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_pPremium,
    headerName: Constants.P_Premium,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_pInnovation,
    headerName: Constants.P_Innovation,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_totalVolumePlanned,
    headerName: Constants.Total_vol_planned,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_pKegsPremium,
    headerName: Constants.P_KegsPrem,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_pKegsInnovation,
    headerName: Constants.P_KegsInnovation,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_pKegsTotal,
    headerName: Constants.P_KegsTotal,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_changeVsRecommended,
    headerName: Constants.Change_Vs_Recommended,
    headerClassName: "header-color",
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_remarks,
    headerName: Constants.Remarks,
    headerClassName: "header-color",
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: true,
    cellClassName: 'editable--cell',
    pinnable: false,
  },
  {
    field: Constants.field_comments,
    headerName: Constants.Comments,
    headerClassName: "header-color",
    cellClassName: 'editable--cell',
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: true,
    pinnable: false,
  },
  {
    field: Constants.field_rebateMass,
    headerName: Constants.Rebate_Mass,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rebatePremium,
    headerName: Constants.Rebate_Premium,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rebateInnovation,
    headerName: Constants.Rebate_Innovation,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rebateKegsPremium,
    headerName: Constants.Rebate_KegsPrem,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_rebateKegsInnovation,
    headerName: Constants.Rebate_KegsInnovation,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_averageCPC,
    headerName: Constants.Avg_CPC,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
  {
    field: Constants.field_averageCPK,
    headerName: Constants.Avg_CPK,
    headerClassName: "header-color",
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    pinnable: false,
  },
];

export const summeryTableColumns: GridColDef[] = [
  {
    field: Constants.field_id,
    headerName: Constants.SrNo,
    headerClassName: "header-color",
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    editable: false,
    colSpan: ({ row }) => {
      if (row.id === 'Total') {
        return 4;
      }
      return undefined;
    },
    cellClassName: (row: any) => {
      console.log(row);
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_role,
    headerName: Constants.Role,
    headerClassName: "header-color",
    minWidth: 130,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    cellClassName: (row: any) => {
      if (row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_channel,
    headerName: Constants.Channel,
    headerClassName: "header-color",
    minWidth: 60,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_name,
    headerName: Constants.Name,
    headerClassName: "header-color",
    minWidth: 220,
    align: 'left',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_territory,
    headerName: Constants.Territory,
    headerClassName: "header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_universe,
    headerName: Constants.Universe,
    headerClassName: "header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_recommended_Mass,
    headerName: Constants.Mass,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_recommended_premium,
    headerName: Constants.Premium,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_recommended_Innovation,
    headerName: Constants.Innovation,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_recommendeD_PREMKEGS,
    headerName: Constants.Premkegs,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_recommendeD_INNOVKEGS,
    headerName: Constants.Innovkegs,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_planned_Mass,
    headerName: Constants.Mass,
    headerClassName: "Planned-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_planned_premium,
    headerName: Constants.Premium,
    headerClassName: "Planned-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_planned_Innovation,
    headerName: Constants.Innovation,
    headerClassName: "Planned-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_planneD_PREMKEGS,
    headerName: Constants.Premkegs,
    headerClassName: "Planned-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_planneD_INNOVKEGS,
    headerName: Constants.Innovkegs,
    headerClassName: "Planned-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_comment_Packaged,
    headerName: Constants.Comment_Packaged,
    headerClassName: "header-color",
    minWidth: 220,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_comment_Kegs,
    headerName: Constants.Comment_Kegs,
    headerClassName: "header-color",
    minWidth: 220,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
];

export const targetTableColumns: GridColDef[] = [
  {
    field: Constants.field_id,
    headerName: Constants.SrNo,
    headerClassName: "header-color",
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    editable: false,
    colSpan: ({ row }) => {
      if (row.id === 'Total') {
        return 3;
      }
      return undefined;
    },
    cellClassName: (row: any) => {
      console.log(row);
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_role,
    headerName: Constants.Role,
    headerClassName: "header-color",
    minWidth: 130,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    editable: false,
    cellClassName: (row: any) => {
      if (row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_name,
    headerName: Constants.Name,
    headerClassName: "header-color",
    minWidth: 220,
    align: 'left',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_territory,
    headerName: Constants.Territory,
    headerClassName: "header-color",
    minWidth: 130,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_Mass,
    headerName: Constants.Mass,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_premium,
    headerName: Constants.Premium,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_white,
    headerName: Constants.White,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_Rise,
    headerName: Constants.Rise,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_Gold,
    headerName: Constants.Gold,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_Blonde,
    headerName: Constants.Blonde,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_Innovation,
    headerName: Constants.Innovation,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_PREMKEGS,
    headerName: Constants.Premkegs,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  },
  {
    field: Constants.field_Target_INNOVKEGS,
    headerName: Constants.Innovkegs,
    headerClassName: "Recommend-header-color",
    minWidth: 80,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    editable: false,
    cellClassName: (row: any) => {
      if (row.id === 'Total' || row.row.role === 'SM') {
        return 'total--cell';
      }
      return '';
    }
  }
];