import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, InputAdornment, Stack, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { utils, writeFileXLSX } from "xlsx";
import * as Constants from '../components/_data/constants';
import { sessionStorage_KeyName } from '../components/_data/constants';
import { gridColumnsVisibility } from '../redux/actions/gridActions';
import { getExportData } from '../redux/api/gridApi';
import { column } from './_data/data';

const ServerSideFilter = forwardRef(({ columnsFiltered, setSearchCriteria, setColumnsFiltered, logOut, updateValue }: any, ref: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isGridDataLoading} = useSelector((state: any) => state.gridReducer);

  const { isUserDetailsLoading, userDetails } = useSelector((state: any) => state.gridReducer);

  const defaultColumnsHide = [
    {
      name: Constants.field_id,
      headerName: Constants.SrNo
    },
    {
      name: Constants.field_salesOfficer,
      headerName: Constants.SO
    },
    {
      name: Constants.field_averageCPK,
      headerName: Constants.Avg_CPK
    },
    {
      name: Constants.field_averageCPC,
      headerName: Constants.Avg_CPC
    },
    {
      name: Constants.field_rebateInnovation,
      headerName: Constants.Rebate_Innovation
    },{
      name: Constants.field_rebateKegsInnovation,
      headerName: Constants.Rebate_KegsInnovation
    },{
      name: Constants.field_rebateKegsPremium,
      headerName: Constants.Rebate_KegsPrem
    },{
      name: Constants.field_rebateMass,
      headerName: Constants.field_rebateMass
    },{
      name: Constants.field_rebatePremium,
      headerName: Constants.field_rebatePremium
    },
    {
      name: Constants.field_aimToContract,
      headerName: Constants.Aim_to_Contract
    },
    {
      name: Constants.field_subChannel,
      headerName: Constants.Sub_Channel
    },
    {
      name: Constants.field_category,
      headerName: Constants.Category
    },
    {
      name: Constants.field_groupFPR,
      headerName: Constants.Group_FPR
    },
    {
      name: Constants.field_premiumness,
      headerName: Constants.Premiumness
    },
    {
      name: Constants.field_newOutlet,
      headerName: Constants.New_Outlet
    },
    {
      name: Constants.field_l3MonthsMass,
      headerName: Constants.L3M_Mass
    },
    {
      name: Constants.field_l3MonthsPremium,
      headerName: Constants.L3M_Prem
    },
    {
      name: Constants.field_l3Monthskeg,
      headerName: Constants.L3M_Keg
    },
    {
      name: Constants.field_l3MonthsPremiumMS,
      headerName: Constants.L3M_Prem_MS
    },
    {
      name: Constants.field_premium,
      headerName: Constants.L3M_Prem
    },
    {
      name: Constants.field_industryDraftAccount,
      headerName: Constants.Industry_Draft_Account
    },
  ];

  // const distributorMarketColumnsHide = [
  //   {
  //     name: Constants.field_exciseLICCode,
  //     headerName: Constants.Excise_Lic_Code
  //   },
  //   {
  //     name: Constants.field_subChannel,
  //     headerName: Constants.Sub_Channel
  //   },
  //   {
  //     name: Constants.field_depot,
  //     headerName: Constants.Depot
  //   },
  //   {
  //     name: Constants.field_groupName,
  //     headerName: Constants.Group_Name
  //   },
  //   {
  //     name: Constants.field_groupFPR,
  //     headerName: Constants.Group_FPR
  //   },
  //   {
  //     name: Constants.field_premiumness,
  //     headerName: Constants.Premiumness
  //   },
  //   {
  //     name: Constants.field_currentContract,
  //     headerName: Constants.Current_Contract
  //   },
  //   {
  //     name: Constants.field_aimToContract,
  //     headerName: Constants.Aim_to_Contract
  //   },
  //   {
  //     name: Constants.field_industryDraftAccount,
  //     headerName: Constants.Industry_Draft_Account
  //   },
  //   {
  //     name: Constants.field_rebateMass,
  //     headerName: Constants.Rebate_Mass
  //   },
  //   {
  //     name: Constants.field_rebatePremium,
  //     headerName: Constants.Rebate_Premium
  //   },
  //   {
  //     name: Constants.field_rebateInnovation,
  //     headerName: Constants.Rebate_Innovation
  //   },
  //   {
  //     name: Constants.field_rebateKegsPremium,
  //     headerName: Constants.Rebate_KegsPrem
  //   },
  //   {
  //     name: Constants.field_rebateKegsInnovation,
  //     headerName: Constants.Rebate_KegsInnovation
  //   },
  //   {
  //     name: Constants.field_averageCPC,
  //     headerName: Constants.Avg_CPC
  //   },
  //   {
  //     name: Constants.field_averageCPK,
  //     headerName: Constants.Avg_CPK
  //   },
  // ];

  const [columnsHide, setColumnsHide] = useState(defaultColumnsHide);
  const [customizeGrid, setCustomizeGrid] = useState<any>(columnsHide);
  const [exportData, setExportData] = useState<any>([]);
  const [isGridUpdated, setIsGridUpdated] = useState<boolean>(false);
  
  useEffect(() => {
    dispatch(gridColumnsVisibility(columnsHide));

    // if (userDetails?.marketType === "Distributor") {
    //   dispatch(gridColumnsVisibility(distributorMarketColumnsHide));
    //   setColumnsHide(distributorMarketColumnsHide);
    //   setCustomizeGrid(distributorMarketColumnsHide);
    // } else {
    // }
  }, [userDetails]);

  const filterData = column.map((column: any) => {
    return {
      name: column.headerName,
      headerName: column.field
    };
  }).filter(columnName => columnName.name !== 'Sr. No.');
  
  const customizeDropdownData = column.map((column: any) => {
    return {
      name: column.field,
      headerName: column.headerName
    }
  }).filter(columnName => columnName.headerName !== 'Sr. No.');
  
  useEffect(() => {
    handleExport();
  }, []);

  const changeColumnSelection = (data: any) => {
    setColumnsFiltered(data);
  }

  const debouncedFetchData = debounce((e: any) => {
    setSearchCriteria(e.target.value);
  }, 500);

  const changeCustomizeGrid = (data: any) => {
    setCustomizeGrid(data);

    dispatch(gridColumnsVisibility(data));
  };

  const handleExport = async () => {
    return await getExportData()
      .then((data: any) => {

        const actualData = data.data;

        const csvData = [
          [
            Constants.Territory,
            Constants.Excise_Lic_Code,
            Constants.Outlet_Name,
            Constants.Bira_ID,
            Constants.Depot,
            Constants.Outlet_Channel,
            Constants.SO,
            Constants.Sub_Channel,
            Constants.Category,
            Constants.Group_Name,
            Constants.Group_FPR,
            Constants.Segment,
            Constants.Premiumness,
            Constants.New_Outlet,
            Constants.Current_Contract,
            Constants.Aim_to_Contract,
            Constants.L3M_Mass,
            Constants.L3M_Prem,
            Constants.L3M_Keg,
            Constants.L3M_Prem_MS,
            Constants.L3M_Prem_Industry,
            Constants.Prem,
            Constants.Industry_Draft_Account,
            Constants.R_Mass,
            Constants.R_White,
            Constants.R_Rise,
            Constants.R_Gold,
            Constants.R_Blonde,
            Constants.R_Premium,
            Constants.R_Innovation,
            Constants.R_KegsPrem,
            Constants.R_KegsInnovation,
            Constants.R_KegsTotal,
            Constants.P_Mass,
            Constants.P_White,
            Constants.P_Rise,
            Constants.P_Gold,
            Constants.P_Blonde,
            Constants.P_Premium,
            Constants.P_Innovation,
            Constants.Total_vol_planned,
            Constants.P_KegsPrem,
            Constants.P_KegsInnovation,
            Constants.P_KegsTotal,
            Constants.Change_Vs_Recommended,
            Constants.Remarks,
            Constants.Comments,
            Constants.Rebate_Mass,
            Constants.Rebate_Premium,
            Constants.Rebate_Innovation,
            Constants.Rebate_KegsPrem,
            Constants.Rebate_KegsInnovation,
            Constants.Avg_CPC,
            Constants.Avg_CPK,
          ],
          ...actualData.map(({
            territory,exciseLICCode, outletName, biraId, depot, outletChannel,
            salesOfficer, subChannel, category, groupName, groupFPR, segment, premiumness, newOutlet,
            currentContract, aimToContract, l3MonthsMass, l3MonthsPremium, l3Monthskeg, l3MonthsPremiumMS, l3MonthsPremiumIndustry,
            premium, industryDraftAccount, rMass, rWhite, rRise, rGold, rBlonde, rPremium,
            rInnovation, rKegsPremium, rKegsInnovation, rKegsTotal, pMass, pWhite, pRise,
            pGold, pBlonde, pPremium, pInnovation, totalVolumePlanned, pKegsPremium, pKegsInnovation,
            pKegsTotal, changeVsRecommended, rebateMass, rebatePremium, rebateInnovation, rebateKegsPremium,
            rebateKegsInnovation, averageCPC, averageCPK
          }: any) => [
             territory,exciseLICCode, outletName, biraId, depot, outletChannel,
              salesOfficer, subChannel, category, groupName, groupFPR, segment, premiumness, newOutlet,
              currentContract, aimToContract, l3MonthsMass, l3MonthsPremium, l3Monthskeg, l3MonthsPremiumMS, l3MonthsPremiumIndustry,
              premium, industryDraftAccount, rMass, rWhite, rRise, rGold, rBlonde, rPremium,
              rInnovation, rKegsPremium, rKegsInnovation, rKegsTotal, pMass, pWhite, pRise,
              pGold, pBlonde, pPremium, pInnovation, totalVolumePlanned, pKegsPremium, pKegsInnovation,
              pKegsTotal, changeVsRecommended, rebateMass, rebatePremium, rebateInnovation, rebateKegsPremium,
              rebateKegsInnovation, averageCPC, averageCPK
            ]),
        ];

        setExportData(csvData);

        return csvData;
      })
      .catch((error: any) => {
          console.error(error);
      });
  }

  const exportToExcel = () => {
    const wb = utils.book_new();
    utils.book_append_sheet(wb, utils.json_to_sheet(exportData));
    writeFileXLSX(wb, Constants.Export_File_Name);
  }

  const SaveClick = () => {
    if(ref.current?.length !== 0) {
      setIsGridUpdated(true);
      updateValue(ref.current);
      setIsGridUpdated(false);
    }else {
      setIsGridUpdated(false);
    }
  }

    return (
      <>
        <Stack direction={'row'} margin='10px 0px' spacing={1} border='1px solid #CCCCCC' borderRadius='5px' justifyContent='space-evenly'>

          <Autocomplete
            limitTags={1}
            onChange={(e: any, data: any) => {
              // e.stopPropagation();
              changeCustomizeGrid(data);
            }}
            value={customizeGrid || []}
            id="customize_grid_dropdown"
            multiple
            sx={{ width: '320px', padding:'10px 0px', marginLeft: '10px !important' }}
            isOptionEqualToValue={(option: any, value: any) => option.headerName === value.headerName}
            size="small"
            // defaultValue={defaultColumnsHide}
            options={customizeDropdownData || []}
            getOptionLabel={(option: any) => (option ? option.headerName : '')}
            // getOptionDisabled={() => columnsFiltered?.length > 1}
            renderInput={(params: any) => (
              <TextField {...params} size="small" placeholder="Select columns to hide" />
            )}
          />
          
          {/* <Autocomplete
            limitTags={1}
            onChange={(_: any, data: any) => changeColumnSelection(data)}
            value={columnsFiltered || []}
            id="column_filter_dropdown"
            multiple
            sx={{ width: '280px', padding:'10px 0px' }}
            isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
            size="small"
            options={filterData || []}
            getOptionLabel={(option: any) => (option ? option.name : '')}
            getOptionDisabled={() => columnsFiltered?.length > 1}
            renderInput={(params: any) => (
              <TextField {...params} size="small" placeholder="Columns filter" />
            )}
            />
          <TextField
            id="column_filter_input"
            placeholder="Search..."
            size="small"
            sx={{ padding:'10px 10px', margin: '0px !important'}}
            onKeyDown={(e) => debouncedFetchData(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />  */}

          <Button
            id='export-grid'
            onClick={SaveClick}
            className='save-button'
            variant='contained'
            color='success'
            disabled={isGridDataLoading || isGridUpdated}
          > Save </Button>

          <Button
            id='export-grid'
            onClick={() => {
              if(ref.current?.length !== 0)
              {
                const confirBox=window.confirm("Please Save your changes")
                if(confirBox === false)
                {
                  navigate('/summary-view')
                }
              }
              else{
                navigate('/summary-view')
              }
            }
            }
            className='summary-button'
          > Summary view </Button>

<Button
            id='export-grid'
            onClick={() => {
              if(ref.current?.length !== 0)
              {
                const confirBox=window.confirm("Please Save your changes")
                if(confirBox === false)
                {
                  navigate('/target-view')
                }
              }
              else{
                navigate('/target-view')
              }
            }
            }
            className='target-button'
          > Target View </Button>

          <Button
            id='export-grid'
            onClick={exportToExcel}
            className='export-button'
          > Export </Button>

          <Button onClick={() => {
            logOut();
            sessionStorage.removeItem(sessionStorage_KeyName);
          }} className='logout-button'>Log Out</Button>
          {/* <CSVLink className="downloadbtn" filename={Export_File_Name} data={exportData}>
            Export to CSV
          </CSVLink> */}
        </Stack>
    </>
  )
})

export default ServerSideFilter;