/* eslint-disable react-hooks/rules-of-hooks */

import {
  Alert,
  Box,
  Snackbar
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { DataGridPro, GridCellParams, GridColumnGroupingModel, GridRowsProp, gridClasses } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from '../components/_data/constants';
import {
  getGridColumnsFailed, getGridColumnsStart, getGridColumnsSuccess, getGridUpdatedData, getTotalCalculatedDataFailed,
  getTotalCalculatedDataStart, getTotalCalculatedDataSuccess,
  getUserDetailsFailed,
  getUserDetailsSuccess
} from '../redux/actions/gridActions';
import { getGridData, getTotalCalculationData, getUserDetails, updateGridData } from '../redux/api/gridApi';
import ServerSideFilter from './ServerSideFilter';
import { column } from './_data/data';

const OutputGrid = ({logOut}: any) => {
  const dispatch = useDispatch();

  const newRef = useRef('');
  const updateRef = useRef<any>([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [filterModel, setFilterModel] = useState({
    items: []
  });

  const [sortModel, setSortModel] = useState([]);

  const page = 0;
  const size = 100;
  const [searchCriteria, setSearchCriteria] = useState("");
  const [columnsFiltered, setColumnsFiltered] = useState([]);
  const [isToasterOpen, setIsToasterOpen] = useState(false);
 
  const { gridColumnsVisibility, isGridDataLoading, gridData, isTotalCalculatedDataIsLoading,
    totalCalculatedData } = useSelector((state: any) => state.gridReducer);
  
  const columnVisibilityModel = gridColumnsVisibility?.map((value: any) => {
    return {
      [value.name]: false,
    }
  });

  const formattedClomunVisibility = Object.assign({}, ...columnVisibilityModel);

  const getGridDataAPICall = (pageNo: number, pageSize: number, columnsFiltered: any, searchCriteria: string, filters: any, sort: any) => {
    const pageAdj = pageNo + 1;
    getGridData({
      page: pageAdj,
      size: pageSize,
      selectedColumnsForFilter: columnsFiltered?.map((value: any) => value.headerName),
      searchValueForFilter: searchCriteria,
      headerFilters: filters,
      sort: sort
    })
    .then((res) => {
      dispatch(getGridColumnsSuccess({
        data: res.data
      }));
    })
    .catch((error) => {
      dispatch(getGridColumnsFailed({
        error
      }));
    });
  }

  const getTotalCalculatedDataAPICall = (pageNo: number, pageSize: number, columnsFiltered: any, searchCriteria: string, filters: any, sort: any) => {
    const pageAdj = pageNo + 1;
    getTotalCalculationData(
      {page: pageAdj,
      size: pageSize,
      selectedColumnsForFilter: columnsFiltered?.map((value: any) => value.headerName),
      searchValueForFilter: searchCriteria,
      headerFilters: filters,
      sort: sort}
    )
    .then((res) => {
      dispatch(getTotalCalculatedDataSuccess({
        data: res.data
      }));
    })
    .catch((error) => {
      dispatch(getTotalCalculatedDataFailed({
        error
      }));
    });
  }
  
  const updateValue = (inputValue: any) => {
    updateGridData(inputValue)
      .then(() => {
        getGridDataAPICall(paginationModel.page, size, columnsFiltered, searchCriteria, filterModel, sortModel);

        getTotalCalculatedDataAPICall(paginationModel.page, size, columnsFiltered, searchCriteria, filterModel, sortModel);

        updateRef.current = [];
        setIsToasterOpen(true)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    console.log('update');
    
    getGridUpdatedData({data:updateRef?.current})

  },[updateRef?.current]);

  useEffect(() => {
    getUserDetails()
      .then((res) => {
        dispatch(getUserDetailsSuccess(res));
      })
      .catch((error) => {
        dispatch(getUserDetailsFailed(error));
      });

    dispatch(getGridColumnsStart());

    dispatch(getTotalCalculatedDataStart());

    getGridDataAPICall(page, size, columnsFiltered, searchCriteria, filterModel, sortModel);
    
    getTotalCalculatedDataAPICall(page, size, columnsFiltered, searchCriteria, filterModel, sortModel);
  }, []);

  useEffect(() => {
    if (prevSearchValue !== undefined && prevSearchValue !== searchCriteria) {
      getGridDataAPICall(paginationModel.page, paginationModel.pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
      getTotalCalculatedDataAPICall(paginationModel.page, paginationModel.pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
    }
  }, [searchCriteria]);

  useEffect(() => {
    if (prevColumnsFiltered !== undefined && prevColumnsFiltered.length !== columnsFiltered.length) {
      getGridDataAPICall(paginationModel.page, paginationModel.pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
      getTotalCalculatedDataAPICall(paginationModel.page, paginationModel.pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
    }
  }, [columnsFiltered]);

  function previousSearchValue(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevSearchValue: any = previousSearchValue(searchCriteria);
  
  function previousColumnsFiltered(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevColumnsFiltered: any = previousColumnsFiltered(columnsFiltered);

  const columnGroupingModel: GridColumnGroupingModel = [
    {
    groupId: Constants.L3M_B9_Sales,
    headerName: Constants.L3M_B9_Sales,
    description: '',
    children: [
        {
          groupId: Constants.L3M_Mass,
          headerName: `${totalCalculatedData.totalL3MonthsMass?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_l3MonthsMass }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.L3M_Prem,
          headerName: `${totalCalculatedData.totalL3MonthsPremium?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_l3MonthsPremium }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.L3M_Keg,
          headerName: `${totalCalculatedData.totalL3Monthskeg?.toLocaleString() || '' }`,
          children: [{field: Constants.field_l3Monthskeg}],
          headerAlign: 'center',
        },
        {
          groupId: Constants.L3M_Prem_MS,
          headerName: ' ', //`${totalCalculatedData.totalL3MonthsPremiumMS?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_l3MonthsPremiumMS }, { field: Constants.field_l3MonthsPremiumIndustry }],
          headerAlign: 'center',
        },
        /* {
          groupId: Constants.L3M_Prem_Industry,
          headerName: `${totalCalculatedData.totalL3MonthsPremiumIndustry?.toLocaleString() || '' }`,
          children: [{field: Constants.field_l3MonthsPremiumIndustry}],
          headerAlign: 'center',
      }, */
    ],
    headerAlign: 'center',
    },
    {
    groupId: Constants.Industry_Forecasts,
    headerName: Constants.Industry_Forecasts,
    description: '',
    children: [
      {
        groupId: Constants.Prem,
        headerName: `${totalCalculatedData.totalPremium?.toLocaleString() || '' }`,
        children: [{ field: Constants.field_premium }],
        headerAlign: 'center',
      },
    ],
    headerAlign: 'center',
    },
    {
    groupId: Constants.Recommended_Volume,
    headerName: Constants.Recommended_Volume,
    description: '',
    headerAlign: 'center',
    children: [
        {
          groupId: Constants.R_Mass,
          headerName: `${totalCalculatedData.totalRMass?.toLocaleString()?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_rMass }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_White,
          headerName: `${totalCalculatedData.totalRWhite?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_rWhite }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_Rise,
          headerName: `${totalCalculatedData.totalRRise?.toLocaleString() || '' }`,
          children: [{field: Constants.field_rRise }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_Gold,
          headerName: `${totalCalculatedData.totalRGold?.toLocaleString() || '' }`,
          children: [{field: Constants.field_rGold }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_Blonde,
          headerName: `${totalCalculatedData.totalRBlonde?.toLocaleString() || '' }`,
          children: [{field: Constants.field_rBlonde }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_Premium,
          headerName: `${totalCalculatedData.totalRPremium?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_rPremium }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_Innovation,
          headerName: `${totalCalculatedData.totalRInnovation?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_rInnovation }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_KegsPrem,
          headerName: `${totalCalculatedData.totalRKegsPremium?.toLocaleString() || '' }`,
          children: [{field: Constants.field_rKegsPremium }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_KegsInnovation,
          headerName: `${totalCalculatedData.totalRKegsInnovation?.toLocaleString() || '' }`,
          children: [{field: Constants.field_rKegsInnovation }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.R_KegsTotal,
          headerName: `${totalCalculatedData.totalRKegsTotal?.toLocaleString() || '' }`,
          children: [{field: Constants.field_rKegsTotal }],
          headerAlign: 'center',
        },
      ],
    },
    {
      groupId: Constants.Planned_Volume,
      headerName: Constants.Planned_Volume,
      description: '',
      children: [
        {
          groupId: Constants.P_Mass,
          headerName: `${totalCalculatedData.totalPMass?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_pMass }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_White,
          headerName: `${totalCalculatedData.totalPWhite?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_pWhite }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_Rise,
          headerName: `${totalCalculatedData.totalPRise?.toLocaleString() || '' }`,
          children: [{field: Constants.field_pRise }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_Gold,
          headerName: `${totalCalculatedData.totalPGold?.toLocaleString() || '' }`,
          children: [{field: Constants.field_pGold }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_Blonde,
          headerName: `${totalCalculatedData.totalPBlonde?.toLocaleString() || '' }`,
          children: [{field: Constants.field_pBlonde }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_Premium,
          headerName: `${totalCalculatedData.totalPPremium?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_pPremium }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_Innovation,
          headerName: `${totalCalculatedData.totalPInnovation?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_pInnovation }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.Total_vol_planned,
          headerName: `${totalCalculatedData.totalVolumePlanned?.toLocaleString() || '' }`,
          children: [{ field: Constants.field_totalVolumePlanned }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_KegsPrem,
          headerName: `${totalCalculatedData.totalPKegsPremium?.toLocaleString() || '' }`,
          children: [{field: Constants.field_pKegsPremium }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_KegsInnovation,
          headerName: `${totalCalculatedData.totalPKegsInnovation?.toLocaleString() || '' }`,
          children: [{field: Constants.field_pKegsInnovation }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.P_KegsTotal,
          headerName: `${totalCalculatedData.totalPKegsTotal?.toLocaleString() || '' }`,
          children: [{field: Constants.field_pKegsTotal }],
          headerAlign: 'center',
        },
      ],
      headerAlign: 'center',
    },
    {
      groupId: Constants.Rebate,
      headerName: Constants.Rebate,
      description: '',
      children: [
        {
          groupId: Constants.Rebate_Mass,
          headerName: `${totalCalculatedData.totalRebateMass?.toLocaleString() || ''}`,
          children: [{ field: Constants.field_rebateMass }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.Rebate_Premium,
          headerName: `${totalCalculatedData.totalRebatePrem?.toLocaleString() || ''}`,
          children: [{ field: Constants.field_rebatePremium }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.Rebate_Innovation,
          headerName: `${totalCalculatedData.totalRebateInnovation?.toLocaleString() || ''}`,
          children: [{field: Constants.field_rebateInnovation }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.Rebate_KegsPrem,
          headerName: `${totalCalculatedData.totalRebateKegsPrem?.toLocaleString() || ''}`,
          children: [{field: Constants.field_rebateKegsPremium }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.Rebate_KegsInnovation,
          headerName: `${totalCalculatedData.totalRebatePrem?.toLocaleString() || ''}`,
          children: [{field: Constants.field_rebateKegsInnovation }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.Avg_CPC,
          headerName: `${totalCalculatedData.totalAvgCPC?.toLocaleString() || ''}`,
          children: [{ field: Constants.field_averageCPC }],
          headerAlign: 'center',
        },
        {
          groupId: Constants.Avg_CPK,
          headerName: `${totalCalculatedData.totalAvgCPK?.toLocaleString() || ''}`,
          children: [{ field: Constants.field_averageCPK }],
          headerAlign: 'center',
        },
      ],
      headerAlign: 'center',
    },
  ];

  const rows: GridRowsProp = gridData?.planningList?.map((value: any, index: number) => {
    return {
      id: index + 1,
      territory: value.territory,
      exciseLICCode: value.exciseLICCode,
      outletName: value.outletName,
      biraId: value.biraId,
      depot: value.depot,
      outletChannel: value.outletChannel,
      salesOfficer: value.salesOfficer,
      subChannel: value.subChannel,
      category: value.category,
      groupName: value.groupName,
      groupFPR: value.groupFPR,
      segment: value.segment,
      premiumness: value.premiumness,
      newOutlet: value.newOutlet,
      currentContract: value.currentContract,
      aimToContract: value.aimToContract,
      l3MonthsMass: value.l3MonthsMass,
      l3MonthsPremium: value.l3MonthsPremium,
      l3Monthskeg: value.l3Monthskeg,
      l3MonthsPremiumMS: value.l3MonthsPremiumMS,
      l3MonthsPremiumIndustry: value.l3MonthsPremiumIndustry,
      premium: value.premium,
      industryDraftAccount: value.industryDraftAccount,
      rMass: value.rMass,
      rWhite: value.rWhite,
      rRise: value.rRise,
      rGold: value.rGold,
      rBlonde: value.rBlonde,
      rPremium: value.rPremium,
      rInnovation: value.rInnovation,
      rKegsPremium: value.rKegsPremium,
      rKegsInnovation: value.rKegsInnovation,
      rKegsTotal: value.rKegsTotal,
      pMass: value.pMass,
      pWhite: value.pWhite,
      pRise: value.pRise,
      pGold: value.pGold,
      pBlonde: value.pBlonde,
      pPremium: value.pPremium,
      pInnovation: value.pInnovation,
      totalVolumePlanned: value.totalVolumePlanned,
      pKegsPremium: value.pKegsPremium,
      pKegsInnovation: value.pKegsInnovation,
      pKegsTotal: value.pKegsTotal,
      changeVsRecommended: value.changeVsRecommended,
      remarks: value.remarks,
      comments: value.comments,
      rebateMass: value.rebateMass,
      rebatePremium: value.rebatePremium,
      rebateInnovation: value.rebateInnovation,
      rebateKegsPremium: value.rebateKegsPremium,
      rebateKegsInnovation: value.rebateKegsInnovation,
      averageCPC: value.averageCPC,
      averageCPK: value.averageCPK      
    }
  }) || [];

  const paginationChange = useCallback((event: any) => {
    const { page, pageSize } = event;
    const pageNo = _.cloneDeep(event);

    setPaginationModel(pageNo);

    getGridDataAPICall(page, pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
    getTotalCalculatedDataAPICall(page, pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
  }, [columnsFiltered, searchCriteria, filterModel, sortModel]);

  const filterChange = useCallback((event: any) => {

    const filterModel = _.cloneDeep(event);

    setFilterModel(filterModel);

    getGridDataAPICall(paginationModel.page, paginationModel.pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
    getTotalCalculatedDataAPICall(paginationModel.page, paginationModel.pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
  }, [paginationModel, columnsFiltered, searchCriteria, sortModel]);

  const sortChange = useCallback((event: any) => {
    const sortModel = _.cloneDeep(event);

    setSortModel(sortModel);

    getGridDataAPICall(paginationModel.page, paginationModel.pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
    getTotalCalculatedDataAPICall(paginationModel.page, paginationModel.pageSize, columnsFiltered, searchCriteria, filterModel, sortModel);
  }, [paginationModel, columnsFiltered, searchCriteria, filterModel]);

 /*  const loaderCondition = (gridDataLoader: boolean, gridTotalLoader: boolean) => {
    let returnValue;
    if ((gridDataLoader && gridTotalLoader) || (gridDataLoader && !gridTotalLoader) || (!gridDataLoader && gridTotalLoader)) {
      returnValue = true;
    } else if (!gridDataLoader && !gridTotalLoader) {
      returnValue = false;
    }

    return returnValue;
  }; */

  const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, Constants.ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        Constants.ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          Constants.ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            Constants.ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
    },
    "& ::-webkit-scrollbar": {
    width: "6px"
  },
  "& ::-webkit-scrollbar-track": {
    backgroundColor: "#f5f5f5"
  },
  "& ::-webkit-scrollbar-thumb": {
    borderRadius: "5px",
    boxShadow: "inset 0 0 4px #7393B3",
    backgroundColor: "#f5f5f5"
  }
  }));
  
  const updateEditedEntries = (biraId: string, propertyName: any, editedValue: any) => {
    let isExists = false;

    updateRef.current?.map((value: any) => {
      if (value.biraId === biraId) {
        isExists = true;
      }
    });

    if (isExists) {
      updateRef.current?.map((value: any) => {
        if (value.biraId === biraId) {
          value[propertyName] = editedValue;
        }
        return editedValue;
      })
    } else {
      updateRef.current = [
        ...updateRef.current,
        { "biraId": biraId, [propertyName]: editedValue, "isUpdated": "Y" }];
    }
  };
  
  const handleProcessRowUpdate = (params: any) => {
    const regex = /^[0-9\b]+$/;

     if (regex.test(params[newRef.current]) || newRef.current === 'comments' || newRef.current === 'remarks') {

       updateEditedEntries(params.biraId, [newRef.current], params[newRef.current]);

       return params;
     }
  };

  const totalCount = gridData?.planningList ? gridData?.planningList[0]?.totalCount : 0;
  const handleToasterClose = () => {
    setIsToasterOpen(false);
  };

  return (
    <>
      <ServerSideFilter
        columnsFiltered={columnsFiltered}
        setColumnsFiltered={setColumnsFiltered}
        setSearchCriteria={setSearchCriteria}
        logOut={logOut}
        ref={updateRef}
        updateValue={updateValue}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isToasterOpen}
        autoHideDuration={5000}
        onClose={handleToasterClose}
      >
        <Alert onClose={handleToasterClose} severity="success">
        PlanningData Update Successfully
        </Alert>
      </Snackbar>
      <Box
        sx={{
          height: '75%',
          width: "100%",
          margin: '10px 0',
          "& .header-color": {
            backgroundColor: "#7393B3",
            fontWeight: 'bold !important'
          },
          '& .editable--cell': {
            backgroundColor: '#F0BF60',
          },
          '& .Increased-volume-cell': {
            backgroundColor: '#90EE90',
          },
          '& .Decreased-volume-cell': {
            backgroundColor: '#FF9999',
          }
        }}
      >
        <StripedDataGrid rows={rows} columns={column}
          //  onCellEditStop={(event) => {
          //   setSaveIsToasterOpen(true);
          // }} 
          onCellEditStart={(params) => {
            newRef.current = params.field
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          getCellClassName={(params: GridCellParams<any, any, any>) => {
            if (params.field === Constants.field_changeVsRecommended) {
              if (params.row.changeVsRecommended === 'Increased Volume') {
                return 'Increased-volume-cell';
              } else if (params.row.changeVsRecommended === 'Decreased Volume') {
                return 'Decreased-volume-cell';
              }
            }
            return '';
          }}
          /* 
          (params): Promise<any> => {
            const columnName = newRef.current;
              updateValue({ "biraId": params.biraId, [newRef.current]: params[newRef.current] });
              return { ...params };
          }
          */
          processRowUpdate={handleProcessRowUpdate}
          /* components={{
            Toolbar: GridToolbar,
            }}
          componentsProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              // showQuickFilter: true,
              // quickFilterProps: { debounceMs: 250 },
            }
          }} */
          density="standard"
          sx={{
            '.MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          disableColumnFilter
          disableDensitySelector
          initialState={{
            columns: {
              ...column,
              columnVisibilityModel: formattedClomunVisibility,
            },
          }}
          hideFooterSelectedRowCount={true}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
          loading={isGridDataLoading}
          paginationMode="server"
          rowCount={totalCount}
          pageSizeOptions={[25, 50, 100, 500]}
          paginationModel={paginationModel}
          onPaginationModelChange={paginationChange}
          pagination
          unstable_headerFilters
          filterMode="server"
          filterModel={filterModel}
          filterDebounceMs={1000}
          onFilterModelChange={filterChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={sortChange}
        />
      </Box>
      </>
  )
}

export default OutputGrid;