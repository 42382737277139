import { Button } from '@mui/material';
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack } from '@mui/system';
import { DataGridPro, GridColumnGroupingModel, gridClasses } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { getGridColumnsStart, getTargetViewSuccess, getTargetViewFailed } from '../../redux/actions/gridActions';
import { getTargetView } from '../../redux/api/gridApi';
import * as Constants from '../_data/constants';
import { targetTableColumns } from '../_data/data';
import { utils, writeFileXLSX } from "xlsx";

const TargetView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { targetList, isTargetDataLoading } = useSelector((state: any) => state.gridReducer);
    const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.primary.main, Constants.ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    Constants.ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        Constants.ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            Constants.ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
        "& ::-webkit-scrollbar": {
            width: "6px"
        },
        "& ::-webkit-scrollbar-track": {
            backgroundColor: "#f5f5f5"
        },
        "& ::-webkit-scrollbar-thumb": {
            borderRadius: "5px",
            boxShadow: "inset 0 0 4px #7393B3",
            backgroundColor: "#f5f5f5"
        }
    }));
    const exportToExcel = () => {
        const wb = utils.book_new();
        utils.book_append_sheet(wb, utils.json_to_sheet(targetList));
        writeFileXLSX(wb, Constants.Export_File_Name);
      }

    const getTotal = (inputColumn: string) => {
        const colValue = targetList?.map((value: any) => {

            return value[inputColumn];
        });
        console.log('inputColumn:-',inputColumn, colValue);
        
        return colValue?.reduce((partialSum: number, next: number) => partialSum + next, 0);
    };

    const rowsData = targetList?.map((value: any, index: number) => {
        return {
            id: index + 1,
            target_Premium: value.target_Premium,
            target_KegsPremium: value.target_KegsPremium,
            target_KegsInnovation: value.target_KegsInnovation,
            target_Mass: value.target_Mass,
            target_Innovation: value.target_Innovation,
            target_White: value.target_White,
            target_Rise: value.target_Rise,
            target_Gold: value.target_Gold,
            target_Blonde: value.target_Blonde,
            // recommended_Premium: value.recommanded_Premium,
            // recommended_Mass: value.recommanded_Mass,
            // recommended_Innovation: value.recommanded_Innovation,
            name: value.name,
            // universe: value.universe,
            role: value.role,
            territory: value.territory,
            // channel: value.channel,
        }
    });

    const finalRows = [
        ...rowsData,
        {
            id: 'Total',
            target_Premium: getTotal('target_Premium'),
            target_KegsPremium: getTotal('target_KegsPremium'),
            target_KegsInnovation: getTotal('target_KegsInnovation'),
            target_Mass: getTotal('target_Mass'),
            target_Innovation: getTotal('target_Innovation'),
            target_White: getTotal('target_White'),
            target_Gold: getTotal('target_Gold'),
            target_Blonde: getTotal('target_Blonde'),
            target_Rise: getTotal('target_Rise'),
            name: '',
            // universe: getTotal('universe'),
            role: '',
            territory: '',
            // channel: '',
        }
    ]

    useEffect(() => {
        getGridColumnsStart();

        getTargetView()
            .then((res) => {
                dispatch(getTargetViewSuccess(res));
            })
            .catch(error => {
                dispatch(getTargetViewFailed(error));
            });
    }, []);

    return (
        <Stack direction={'column'} justifyContent='space-evenly' spacing={1} alignItems='end' sx={{ height: '100%', width: "100%" }}>
           <Stack justifyContent='space-between' spacing={1} direction={'row'}>
           <Button
                id='export-grid'
                color="secondary"
                variant='contained'
                // sx={{backgroundColor: 'orange'}}
                onClick={() => navigate('/')}
            > Back </Button>

            <Button
            id='export-grid'
            onClick={exportToExcel}
            className='export-target-button'
          > Export </Button>
           </Stack>
            <Box
                sx={{
                    height: '100%',
                    width: "100%",
                    "& .header-color": {
                        backgroundColor: "#7393B3",
                        fontWeight: 'bold !important'
                    },
                    "& .Planned-header-color": {
                        backgroundColor: "#574AE8",
                        fontWeight: 'bold !important'
                    },
                    "& .Recommend-header-color": {
                        backgroundColor: "#2EE677",
                        fontWeight: 'bold !important'
                    },
                    '& .editable--cell': {
                        backgroundColor: '#F0BF60',
                        color: 'red',
                    },
                    '& .total--cell': {
                        fontWeight: 'bold'
                    },
                    '& .Increased-volume-cell': {
                        backgroundColor: '#90EE90',
                    },
                    '& .Decreased-volume-cell': {
                        backgroundColor: '#FF9999',
                    }
                }}
            >
                <StripedDataGrid
                    rows={finalRows}
                    columns={targetTableColumns || []}
                    density="compact"
                    disableColumnFilter
                    disableDensitySelector
                    disableColumnSelector
                    hideFooterSelectedRowCount={true}
                    experimentalFeatures={{ columnGrouping: true }}
                    loading={isTargetDataLoading}
                    hideFooterPagination={true}
                    sx={{
                        '.MuiDataGrid-columnSeparator': {
                            visibility: 'visible',
                        },
                    }}
                />
            </Box>
        </Stack>
    )
}

export default TargetView;