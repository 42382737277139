export const SHOW_HIDE_GRID_COLUMNS = 'SHOW_HIDE_GRID_COLUMNS';

export const GET_GRID_DATA_START = 'GET_GRID_DATA_START';
export const GET_GRID_DATA_SUCCESS = 'GET_GRID_DATA_SUCCESS';
export const GET_GRID_DATA_FAILED = 'GET_GRID_DATA_FAILED';

export const GET_TOTAL_CALCULATED_DATA_START = 'GET_TOTAL_CALCULATED_DATA_START';
export const GET_TOTAL_CALCULATED_DATA_SUCCESS = 'GET_TOTAL_CALCULATED_DATA_SUCCESS';
export const GET_TOTAL_CALCULATED_DATA_FAILED = 'GET_TOTAL_CALCULATED_DATA_FAILED';

export const UPDATE_GRID_DATA_SUCCESS = 'UPDATE_GRID_DATA_SUCCESS';
export const UPDATE_GRID_DATA_FAILED = 'UPDATE_GRID_DATA_FAILED';

export const GRID_COLUMNS_VISIBILITY = 'GRID_COLUMNS_VISIBILITY';

export const GET_SUMMERY_VIEW_START = 'GET_SUMMERY_VIEW_START';
export const GET_SUMMERY_VIEW_SUCCESS = 'GET_SUMMERY_VIEW_SUCCESS';
export const GET_SUMMERY_VIEW_FAILED = 'GET_SUMMERY_VIEW_FAILED';

export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const GET_GRID_UPDATED_DATA = 'GET_GRID_UPDATED_DATA';

export const GET_TARGET_VIEW_START = 'GET_TARGET_VIEW_START';
export const GET_TARGET_VIEW_START_SUCCESS = 'GET_TARGET_VIEW_START_SUCCESS';
export const GET_TARGET_VIEW_START_FAILED = 'GET_TARGET_VIEW_START_FAILED';