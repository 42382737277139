import { Button, Container, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Logo2 from '../../Metadata/Logo-02.png';

export default function Login({login}: any) {
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <>
    <Container maxWidth={false} sx={{height: "100vh"}}>
      <Box
        sx={{
          marginTop: 0,
          height: '100%'
        }}
      >
        <Grid container sx={{height: '100%'}}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${Logo2})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "dark"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            sx={{
              backgroundColor: "#E0E0E0",
            }}
          >
            <Box
                sx={{
                my: 25,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h3" fontFamily="sans-serif">
                BIRA 91
              </Typography>

              <Typography component="h1" variant="h5" fontFamily="sans-serif">
                Outlet Planning Tool
              </Typography>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => login()}
                >
                  Log In
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      </Container>
      </>
  );
}