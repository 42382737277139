import { Button } from '@mui/material';
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack } from '@mui/system';
import { DataGridPro, GridColumnGroupingModel, gridClasses } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { getGridColumnsStart, getSummeryViewFailed, getSummeryViewSuccess } from '../../redux/actions/gridActions';
import { getSummeryView } from '../../redux/api/gridApi';
import * as Constants from '../_data/constants';
import { summeryTableColumns } from '../_data/data';

const SummaryView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { summeryViewData, isSummeryDataLoading } = useSelector((state: any) => state.gridReducer);
    const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.primary.main, Constants.ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    Constants.ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        Constants.ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            Constants.ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
        "& ::-webkit-scrollbar": {
            width: "6px"
        },
        "& ::-webkit-scrollbar-track": {
            backgroundColor: "#f5f5f5"
        },
        "& ::-webkit-scrollbar-thumb": {
            borderRadius: "5px",
            boxShadow: "inset 0 0 4px #7393B3",
            backgroundColor: "#f5f5f5"
        }
    }));

    const getTotal = (inputColumn: string) => {
        const colValue = summeryViewData?.map((value: any) => {

            return value[inputColumn];
        });
        return colValue?.reduce((partialSum: number, next: number) => partialSum + next, 0);
    };

    const rowsData = summeryViewData?.map((value: any, index: number) => {
        return {
            id: index + 1,
            comment_Kegs: value.comment_Kegs,
            comment_Packaged: value.comment_Packaged,
            planneD_PREMKEGS: value.planned_Kegs,
            planneD_INNOVKEGS: value.planned_InnovationKegs,
            planned_Premium: value.planned_Premium,
            planned_Mass: value.planned_Mass,
            planned_Innovation: value.planned_Innovation,
            recommendeD_PREMKEGS: value.recommanded_Kegs,
            recommendeD_INNOVKEGS: value.recommanded_InnovationKegs,
            recommended_Premium: value.recommanded_Premium,
            recommended_Mass: value.recommanded_Mass,
            recommended_Innovation: value.recommanded_Innovation,
            name: value.name,
            territory: value.territory,
            universe: value.universe,
            role: value.role,
            channel: value.channel,
        }
    });

    const finalRows = [
        ...rowsData,
        {
            id: 'Total',
            comment_Kegs: '',
            comment_Packaged: '',
            planneD_PREMKEGS: getTotal('planned_Kegs'),
            planneD_INNOVKEGS: getTotal('planned_InnovationKegs'),
            planned_Premium: getTotal('planned_Premium'),
            planned_Mass: getTotal('planned_Mass'),
            planned_Innovation: getTotal('planned_Innovation'),

            recommendeD_PREMKEGS: getTotal('recommanded_Kegs'),
            recommendeD_INNOVKEGS: getTotal('recommanded_InnovationKegs'),
            recommended_Premium: getTotal('recommanded_Premium'),
            recommended_Mass: getTotal('recommanded_Mass'),
            recommended_Innovation: getTotal('recommanded_Innovation'),

            name: '',
            territory: '',
            universe: getTotal('universe'),
            role: '',
            channel: '',
        }
    ]

    const columnGroupingModel: GridColumnGroupingModel = [
        {
            groupId: Constants.recommended_summary_title,
            headerName: Constants.recommended_summary_title,
            description: '',
            children: [
                { field: Constants.field_recommended_premium },
                { field: Constants.field_recommendeD_PREMKEGS },
                { field: Constants.field_recommendeD_INNOVKEGS },
                { field: Constants.field_recommended_Mass },
                { field: Constants.field_recommended_Innovation },
            ],
            headerAlign: 'center',
        },
        {
            groupId: Constants.planned_summary_title,
            headerName: Constants.planned_summary_title,
            description: '',
            children: [
                { field: Constants.field_planned_premium },
                { field: Constants.field_planneD_PREMKEGS },
                { field: Constants.field_planneD_INNOVKEGS },
                { field: Constants.field_planned_Mass },
                { field: Constants.field_planned_Innovation },
            ],
            headerAlign: 'center',
        },
    ];

    useEffect(() => {
        getGridColumnsStart();

        getSummeryView()
            .then((res) => {
                dispatch(getSummeryViewSuccess(res));
            })
            .catch(error => {
                dispatch(getSummeryViewFailed(error));
            });
    }, []);

    return (
        <Stack direction={'column'} spacing={1} alignItems='end' sx={{ height: '100%', width: "100%" }}>
            <Button
                id='export-grid'
                color="secondary"
                variant='contained'
                // sx={{backgroundColor: 'orange'}}
                onClick={() => navigate('/')}
            > Back </Button>

            <Box
                sx={{
                    height: '100%',
                    width: "100%",
                    "& .header-color": {
                        backgroundColor: "#7393B3",
                        fontWeight: 'bold !important'
                    },
                    "& .Planned-header-color": {
                        backgroundColor: "#574AE8",
                        fontWeight: 'bold !important'
                    },
                    "& .Recommend-header-color": {
                        backgroundColor: "#2EE677",
                        fontWeight: 'bold !important'
                    },
                    '& .editable--cell': {
                        backgroundColor: '#F0BF60',
                        color: 'red',
                    },
                    '& .total--cell': {
                        fontWeight: 'bold'
                    },
                    '& .Increased-volume-cell': {
                        backgroundColor: '#90EE90',
                    },
                    '& .Decreased-volume-cell': {
                        backgroundColor: '#FF9999',
                    }
                }}
            >
                <StripedDataGrid
                    rows={finalRows}
                    columns={summeryTableColumns || []}
                    density="compact"
                    disableColumnFilter
                    disableDensitySelector
                    disableColumnSelector
                    hideFooterSelectedRowCount={true}
                    columnGroupingModel={columnGroupingModel}
                    experimentalFeatures={{ columnGrouping: true }}
                    loading={isSummeryDataLoading}
                    hideFooterPagination={true}
                    sx={{
                        '.MuiDataGrid-columnSeparator': {
                            visibility: 'visible',
                        },
                    }}
                />
            </Box>
        </Stack>
    )
}

export default SummaryView;