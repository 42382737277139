import NavBar from './NavBar/navBar';
import OutputGrid from './outputGrid';

const Index = ({logOut}: any) => {
  /* 
  <Container fixed >
      <Box sx={{ bgcolor: `${themeColor}`, height: '100vh' }} >
        <OutputGrid />
      </Box>
    </Container>

    <Box sx={{ bgcolor: `${themeColor}`}} >
      <Grid container>
        <NavBar />
        <OutputGrid />
      </Grid>
    </Box>
     */
  return (
      <>
        <NavBar />
        
        <OutputGrid logOut={logOut} />
      </>
  )
}

export default Index;